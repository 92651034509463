.footer__container {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footerIcon {
    font-size: 2rem;
}

@media screen and (max-width: 550px) {
    .footerIcon {
        font-size: 1rem;
    }
}