* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Anton', sans-serif;
  color: red;
}

body {
  background-color: black;
  padding: 1rem 5rem;
  position: relative;
}

.active-nav-button {
  color: rgb(226, 18, 226);
}

.heading {
  text-align: center;
  width: 80%;
  margin: 3rem auto;
}

.padding-page {
  margin: 5rem 0rem;
}

.heading h1 {
  text-decoration: underline;
  font-size: 3rem;
  margin-bottom: 3rem;
}

.heading p {
  font-size: 1.5rem;
}

.link {
  text-decoration: none;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* NAVBAR COMP ********************************************************/

.navbar__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
  position: sticky;
  top: 0;
  z-index: 100;
  margin-top: 0rem;
  background-color: black;
}

.navbar__container__logo {
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar__container__logo .logo-icon {
  font-size: 4rem;
}

.navbar__container__links-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar__container__links-container__links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.link {
  font-size: 1.5rem;
  margin-left: 3rem;
}

.link:hover {
  color: rgb(226, 18, 226);
}

.hamburger-menu {
  font-size: 2rem;
  cursor: pointer;
}

.navbar__container__links-container__menu {
  display: none;
  margin-left: 3rem;
}

/* HOME PAGE **********************************************************/

.home__container {
  display: flex;
  gap: 5rem;
}

.home__container__heading {
  flex: 1;
}

.home__container__images {
  flex: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.home__container__images__grid {
  min-height: 40vh;
  max-height: 80vh;
  display: flex;
}

.home__container__image__flex {
  min-height: 40vh;
  max-height: 80vh;
}

.home__container__images__grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 40vh;
  max-height: 80vh;
}

.home__container__images__grid div {
  flex: 1;
  height: 50%;
  width: 100%;
  background-color: blue;
}

/* MENU PAGE *************************************************************/

.menu__container__heading-container__buttons {
  width: 80%;
  margin: 0px auto;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.menu__container__heading-container__buttons button {
  padding: 0.5rem;
  background-color: red;
  border: none;
  border-radius: 0.5rem;
  color: black;
  font-size: 1.7rem;
  cursor: pointer;
  width: 130px;
}

.menu__container__heading-container__buttons button:hover {
  background-color: rgb(226, 18, 226);
}

.menu__container__menu {
  width: 80%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

/* FAVOURITES PAGE ****************************************************************/

.favourites__container__items {
  width: 80%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.favouriteMeals {
  font-size: 3rem;
  text-align: center;
}

/* ABOUTUS PAGE  *******************************************************************/

.aboutus__container__images{
  width: 80%;
  margin: 0px auto;
  text-align: center;
  font-size: 3rem;
}

/* CONTACT PAGE *********************************************************************/ 
.contact__container__phone, .contact__container__adress, .contact__container__map {
  font-size: 3rem;
  text-align: center;
}

.contact__container__map {
  width: 80%;
  margin: 0px auto;
  margin-top: 3rem;
}

/****************************** RESPONSIVE MEDIA *************************************/

@media  screen and (max-width: 1800px) {
  .navbar__container__logo h1 {
    font-size: 3rem;
  }

  .link {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 1650px) {
  .navbar__container__logo h1 {
    font-size: 3rem;
  }

  .link {
    margin-left: 2.2rem;
  }
}

@media screen and (max-width: 1500px) {
  .navbar__container__logo h1 {
    font-size: 3rem;
  }

  .link {
    font-size: 1.6rem;
  }

  .home__container__images {
    grid-template-columns: 1fr;
  }

  .home__container__images__grid {
    grid-template-columns: repeat(2,1fr);
  }

  .navbar__container__logo .logo-icon {
    font-size: 4rem;
  }

  .link {
    font-size: 1.3rem;
  }

  .heading h1 {
  font-size: 3.5rem;
  }

  .heading p {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1350px) {
  .home__container {
    flex-direction: column;
  }
  .navbar__container__logo h1 {
    font-size: 2.5rem;
  }

  .navbar__container__links-container__links .link {
    font-size: 1.5rem;
    margin-left: 1.8rem;
  }

  .home__container__images {
   display: flex;
  }

  .home__container__images__grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1250px) {

  .contact__container__phone, .contact__container__adress, .contact__container__map {
    font-size: 2rem;
    text-align: center;
  }

  .home__container {
    flex-direction: column;
  }

  .navbar__container__logo h1 {
    font-size: 2rem;
  }

  .navbar__container__logo .logo-icon {
    font-size: 3rem;
  }

  .navbar__container__links-container__links .link {
    font-size: 1.1rem;
  }

  .heading h1 {
    font-size: 3rem;
  }

  .heading p {
    font-size: 1rem;
  }
}


@media screen and (max-width: 1000px) {
  body {
    padding: 1rem 3rem;
  }
  .padding-page {
    margin: 1rem 0rem;
  }

  .navbar__container__logo h1 {
    font-size: 1.5rem;
  }

  .navbar__container__logo .logo-icon {
    font-size: 2.5rem;
  }

  .navbar__container__links-container__links .link {
    font-size: 1rem;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 800px) {
  body {
    padding: 1rem 2rem;
  }

  .contact__container__phone, .contact__container__adress, .contact__container__map {
    font-size: 1.5rem;
    text-align: center;
  }

  .navbar__container__logo h1 {
    font-size: 1.5rem;
  }

  .navbar__container__logo .logo-icon {
    font-size: 2rem;
  }

  .navbar__container__links-container__links .link {
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }

  .menu__container__heading-container__buttons button {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 650px) {
  body {
    padding: 0rem 1rem;
  }

  .menu__container__heading-container__buttons button {
    max-width: 100px;
  }
}

@media screen and (max-width: 550px) {
  body {
    overflow-x: hidden;
  }

  .navbar__container {
    padding-top: 0.5rem;
  }

  .menu__container {
    position: relative;
    z-index: 0;
  }

  .navbar__container__logo h1 {
    font-size: 1.3rem;
  }

  .menu__container__heading-container__buttons {
    gap: 0.5rem;
  }

  .menu__container__heading-container__buttons button {
    font-size: 1rem;
    /* min-width: 50px; */
    max-width: 80px;
  }

  .navbar__container__links-container__links {
    position: fixed;
    top: 7.8vh;
    right: 0rem;
    height: 100%;
    transform: translateX(100%);
    background-color: black;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    transition: all 0.8s ease;
    z-index: 1000;
    background-color: black;
  }

  .navbar__container__links-container__links .link {
    margin-bottom: 3rem;
    font-size: 1.5rem;
  }

  .navbar__container__links-container__menu {
    display: block;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 0.8rem;
  }

  .home__container {
    gap: 0.5rem;
  }

  .favouriteMeals {
    font-size: 2rem;
  }

  .favourites__container__items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .aboutus__container__images h3 {
    font-size: 2rem;
  }

  .contact__container__phone h3 {
    font-size: 1.5rem;
  }

  .contact__container__map h3 {
    font-size: 1.5rem;
  }

  .aboutus__container__images h3 {
    font-size: 1.5rem;
  }
}

.showMenu {
  transform: translateX(0%);
}

.active-menu-button {
  background-color: rgb(226, 18, 226) !important;
}

.active-menuItem-button {
  background-color: rgb(226, 18, 226);
}