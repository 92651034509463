.menuItem__container {
    border: 3px solid red;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 500px;
    width: 400px;
}

.menuItem__container__image {
    width: 100%;
    height: 65%;
}

.menuItem__container__image img {
    border-radius: 0.5rem;
}

.menuItem__container__content {
    height: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
}

.menuItem__container__content h3{
    font-size: 2rem;
}

.menuItem__container__content__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menuItem__container__content__buttons button {
    background-color: red;
    color: black;
    border: none;
    padding: 0.3rem;
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 1.5rem;
}


.menuItem__container__content__buttons button:hover {
    background-color: rgb(226, 18, 226);
}

.star-icon {
    font-size: 2rem;
    cursor: pointer;
}

/* INFO SIDE ************************************************************/

.info {
    height: 500px;
    width: 400px;
    border: 3px solid red;
    padding: 0.5rem;
    border-radius: 0.5rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.info h3 {
    font-size: 2rem;
}

.info__content-container {
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.info__buttonsAll-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info__buttonsAll-container button {
    font-size: 1.3rem;
    padding: 0.3rem;
    background-color: red;
    color: black;
    border: none;
    border-radius: 0.3rem;
    margin-top: 1rem;
    cursor: pointer;
}

.menuItem__container__buttons-container__buttons {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

ul li {
    margin-left: 1.3rem;
    font-size: 1.3rem;
}

p {
    font-size: 1.3rem;
}

.info__buttonsAll-container button:hover {
    background-color:  rgb(226, 18, 226);
}

.info__container__text {
    height: 80%;
    margin-top: 1.5rem;
}

.active-menuItem-button {
    background-color: rgb(226, 18, 226) !important;
}

/* RESPONSIVE ****************************************************************/

@media screen and (max-width: 650px) {
    .menuItem__container, .info {
        width: 350px;
        height: 400px;
    }

    .menuItem__container h3, .info h3 {
        font-size: 1.7rem;
    }

    .menuItem__container__content__buttons button {
        font-size: 1.1rem;
    }

    .star-icon {
        font-size: 1.7rem;
    }

    /* info  ******************************************************************/

    .info__buttonsAll-container button {
        font-size: 1rem;
    }

    .menuItem__container__buttons-container__buttons {
        width: 73%;
    }

    p {
        font-size: 1rem;
    }

    ul li {
        font-size: 1rem;
    }
}